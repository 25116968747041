import { styled, Box, Typography, Button } from '@mui/material';
import TextField from '@mui/material/TextField';

export const CreateMeetingWrapper = styled('div')`
  min-height: calc(100vh - 90px);
  display: flex;
  width: 100%;
  overflow: hidden;
`;

export const AlignWrapper = styled(Box)`
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
`;

export const InnerAlign = styled(Box)`
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
`;

export const ContainerWrapper = styled(Box)`
  max-width: 1110px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
`;

export const ColsWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;

  @media (min-width: 768px){
    gap: 48px;
  }
  @media (min-width: 1200px){
    gap: 98px;
  }
`;

export const ColWrapper = styled(Box)`
  width: 100%;

  @media (min-width: 768px){
    flex-grow: 1;
    flex-basis: 0;
    min-width: calc(50% - 98px);
    display: flex;
  }
`;

export const LogoWrapper = styled(Box)`
  max-width: 100px;
  margin-bottom: 24px;

  @media (min-width: 992px){
    max-width: 130px;
  }
`;

export const TextParagraph = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
  line-height: 1.36;
  color: #C0C0C0;

  @media (min-width: 1200px){
    font-size: 22px;
  }
`;

export const ActionsWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 28px;

  @media (min-width: 992px){
    margin-top: 48px;
    flex-wrap: nowrap;
  }
`;

export const FormGroup = styled(Box)`
  position: relative;
  width: 100%;

  @media (min-width: 576px){
    width: 207px;
  }
  @media (min-width: 1200px){
    width: 277px;
  }

  &.small{
    width: 100%;

    @media (min-width: 576px){
      width: 145px;
    }
    @media (min-width: 1200px){
      width: 185px;
    }
  }
`;

export const LabelIcon = styled('i')`
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #575757;
  line-height: 0;
  z-index: 1;
`;

export const FormField = styled(TextField)`
  color: #e9e9e9;
  box-sizing: border-box;
  background-color: #131313;
  border: 1px solid #373737;
  border-radius: 6px;
  display: block;
  width: 100%;
  height: 48px;
  font-size: 14px;
  line-height: 18px;

  input{
    padding: 8px 12px 8px 40px;
    color: #e9e9e9;
    font-size: 14px;
    line-height: 18px;

    &::placeholder{
      color: #575757;
      opacity: 1;
    }
  }

  &:focus,
  &.Mui-focused{
    outline: none;
    border-color: #E3C050;
  }

  fieldset,
  > div{
    width: 100%;
    height: 100%;
  }

  fieldset{
    outline: none !important;
    border: 0 !important;
    box-shadow: none !important;
  }
`;

export const ButtonPrimary = styled(Button)`
  min-width: 1px;
  background-color: #000;
  color: #E9E9E9;
  text-transform: uppercase;
  border-radius: 6px;
  border: 1px solid #E3C050;
  transition: all 0.3s ease;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
  padding: 12px 20px;
  width: 100%;

  @media (min-width: 576px){
    width: auto;
  }
  @media (min-width: 1200px){
    padding-left: 30px;
    padding-right: 30px;
  }

  &:hover{
    background-color: #454648;
    color: #E9E9E9;
    border-color: #E3C050;
  }

  .MuiSvgIcon-root{
    width: 17px;
    height: 17px;
    margin-right: 7px;
  }
`;

export const ButtonSecondary = styled(Button)`
  min-width: 1px;
  background-color: #000;
  color: #c0c0c0;
  text-transform: uppercase;
  border-radius: 6px;
  border: 1px solid #373737;
  background-color: #131313;
  transition: all 0.3s ease;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
  padding: 12px 24px;
  width: 100%;

  @media (min-width: 576px){
    width: auto;
  }

  &:hover{
    background-color: #454648;
    color: #E9E9E9;
    border-color: #E3C050;
  }
`;

export const ImageWrapper = styled(Box)`
  text-align: center;
  max-width: 290px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 768px){
    text-align: right;
    max-width: none;
    margin-left: 0;
    margin-right: 0;
  }
  
  img{
    max-width: 100%;
    height: auto;
  }
`;