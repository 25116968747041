import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  GoogleAuthProvider,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithCredential,
  signInWithEmailLink,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { toast } from "react-toastify";

const firebaseConfig = {
  apiKey: "AIzaSyC6G9a6RBLdNZ-HAioWQ36zAd0wcf827JQ",
  authDomain: "authentication.6ix.com",
  projectId: "ix-websites",
  storageBucket: "ix-websites.appspot.com",
  messagingSenderId: "1052984193883",
  appId: "1:1052984193883:web:02877a90652471500fa07e",
  measurementId: "G-DP68RWMNLB",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
getAnalytics(app);
const googleProvider = new GoogleAuthProvider({
  prompt: "select_account",
});

const signInWithGoogle = async () => {
  try {
    await signInWithPopup(auth, googleProvider);
    if (auth.currentUser) {
      toast("You are now logged in ");
      return true;
    } else {
      toast("Error:  Login faild");
      return false;
    }
  } catch (err) {
    toast(errorSplit(err.message));
    return false;
  }
};

const signInWithCredentialResponse = async (response) => {
  if (response) {
    try {
      const cred = GoogleAuthProvider.credential(response.credential);
      await signInWithCredential(auth, cred);
      if (auth.currentUser) {
        toast("You are now logged in");
      } else {
        toast("Error:  Login faild");
      }
      return true;
    } catch (err) {
      toast(errorSplit(err.message));
      return false;
    }
  } else {
    toast(errorSplit("Something went wrong try again"));
    return false;
  }
};

const signInWithUserEmailLink = async (email) => {
  try {
    let url = window.location.href;
    url = url.match(/\?./)
      ? url + "&ref_l_email=" + email
      : url + "?ref_l_email=" + email;

    const actionCodeSettings = {
      url: url,
      handleCodeInApp: true,
    };

    return sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        toast({
          title: "An email with the link has been sent to " + email,
        });
        return "An email with the link has been sent to " + email;
      })
      .catch((err) => {
        toast(errorSplit(err.message));
        return false;
      });
  } catch (err) {
    toast(errorSplit(err.message));
    return false;
  }
};
const signInWithUserEmailLinkVerify = (email) => {
  if (isSignInWithEmailLink(auth, window.location.href)) {
    if (!email) {
      email = window.prompt("Please provide your email for confirmation");
    }

    return signInWithEmailLink(auth, email, window.location.href)
      .then(async (result) => {
        if (auth.currentUser) {
          toast("You are now logged in ");
          return true;
        } else {
          toast("Error:  Login faild");
          return false;
        }
      })
      .catch((err) => {
        toast(errorSplit(err.message));
        return false;
      });
  } else {
    toast("Error:  Something went wrong");
    return false;
  }
};

const userLogout = () => {
  signOut(auth);
  localStorage.removeItem("uid");
  window.location.href = "/";
};

const errorSplit = (error) => {
  if (error) {
    if (error.indexOf("/") > -1) {
      error = error.split("/")[1];
      if (error.indexOf(")") > -1) {
        error = error.split(")")[0];
      }
      error = "Error : " + error;
    } else if (error.indexOf("Firebase:") > -1) {
      error = error.split("Firebase:")[1];
    }
  }
  return error;
};

export {
  auth,
  signInWithGoogle,
  signInWithUserEmailLink,
  signInWithUserEmailLinkVerify,
  userLogout,
  signInWithCredentialResponse,
};
