import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const VidPlayIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="20"
      height="26"
      viewBox="0 0 20 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M2.1001 3.36852C2.1001 2.56982 2.99024 2.09343 3.6548 2.53647L18.102 12.1679C18.6958 12.5638 18.6958 13.4362 18.102 13.832L3.6548 23.4635C2.99024 23.9066 2.1001 23.4302 2.1001 22.6315V3.36852Z" stroke="currentColor" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
};