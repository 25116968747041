import { styled, Box, Typography } from '@mui/material';

export const Wrapper = styled('div')`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Loader = styled(Box)`
  width: 64px;
  height: 64px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
  }
`;

export const LoaderHeading = styled(Typography)`
  margin: -1px;
  width: 1px;
  height: 1px;
  clip: rect(0,0,0,0);
  opacity: 0;
  visibility: hidden;
`;