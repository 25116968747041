import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const GlobeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9.00001 16.5C13.1421 16.5 16.5 13.1421 16.5 9.00001C16.5 4.85787 13.1421 1.5 9.00001 1.5C4.85787 1.5 1.5 4.85787 1.5 9.00001C1.5 13.1421 4.85787 16.5 9.00001 16.5Z" fill="none" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M1.5 9H16.5" fill="none" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9 1.5C10.876 3.55377 11.9421 6.21903 12 9.00001C11.9421 11.781 10.876 14.4462 9 16.5C7.12404 14.4462 6.05794 11.781 6 9.00001C6.05794 6.21903 7.12404 3.55377 9 1.5V1.5Z" fill="none" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
};