import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const VideoIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="none" d="M17.5285 5.62256L12.3113 9.34912L17.5285 13.0757V5.62256Z" stroke="currentColor" strokeWidth="1.65625" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M10.8206 4.13379H2.62216C1.79891 4.13379 1.13153 4.80116 1.13153 5.62441V13.0775C1.13153 13.9008 1.79891 14.5682 2.62216 14.5682H10.8206C11.6438 14.5682 12.3112 13.9008 12.3112 13.0775V5.62441C12.3112 4.80116 11.6438 4.13379 10.8206 4.13379Z" stroke="currentColor" strokeWidth="1.65625" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
};