import { styled } from '@mui/material';

export const FilterbarWrapper = styled('div')`
  padding: 20px;
  transition: padding 0.3s ease;
  background-color: #131313;

  @media (min-width: 768px){
    padding-left: 40px;
    padding-right: 40px;
  }

  .sidebar-wrapper-active &{

    @media (min-width: 768px){
      padding-left: 300px;
    }
  }
`;

export const FilterBarBox = styled('div')`
  
  .fbbBoxWrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }

  .fbbBoxColumnWrapper{
    width: calc(40% - 12px);
  }

  .fbbBoxColumnField{
    color: #fff;
    width: 100%;

    input{
      color: #e9e9e9;
      // height: 34px;
      // padding: 5px 20px;
    }

    fieldset, fieldset:hover{
      // height: 44px;

      &, &:hover{
        border-color: #373737 !important;
        border-radius: 6px;
      }
    }

    label, legend{
      color: #575757 !important;
    }
  }

  .fbbbcFieldBoxWrapper{
    display: flex;
    column-gap: 12px;
    align-items: center;
    width: calc(20.35% - 12px);

    .field-label{
      flex-shrink: 0;
    }
  }

  .fbbbcFieldControl{
    flexGrow: 1;
    position: relative;

    fieldset, fieldset:hover{
      border-color: #373737 !important;
      border-radius: 6px;
    },

    .MuiSelect-select{
      color: #e9e9e9;
    }

    .MuiSvgIcon-root{
      color: #fff;
    }
  }

  .fbbbcFieldBoxWrapperExtra{
    display: flex;
    column-gap: 12px;
    align-items: center;
    width: calc(14.8% - 12px);

    .field-label{
      flex-shrink: 0;
    }

    fieldset, fieldset:hover{
      border-color: #373737 !important;
      border-radius: 6px;
    }
  }

  .fbbbcFieldWrapperExtraControl{
    flex-grow: 1;

    .MuiSelect-select{
      color: #e9e9e9;
    }

    .MuiSvgIcon-root{
      color: #fff;
    }
  }

  .fbbbcRelWrapper{
    display: flex;
    column-gap: 12px;
    align-items: center;
    width: calc(20.45% - 12px);

    .btn-reload{
      width: 52%;
      border: 1px solid #E3C050;
      border-radius: 6px;
      color: #fff;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 700;
      background-color: #000;
      height: 56px;

      &.disabled{
        color: #454648;
        pointer-events: none;
        border-color: #373737;
        background-color: transparent;
      }

      &:hover{
        background-color: #454648;
        color: #fff;
      }

      svg{
        width: 15px;
        height: 15px;
        margin-left: 12px;
      }
    }
  }

  .fbbbcbcRelWrapper{
    display: flex;
    column-gap: 12px;
    align-items: center;
    flex-grow: 1;

    .MuiFormControlLabel-label{
      flex-shrink: 0;
    }

    .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd{
      margin-right: 0;
      margin-left: 0;
      column-gap: 12px;
    }
  }

  .fbbbcbcRelControl{

    .MuiSwitch-root.MuiSwitch-sizeMedium{
      width: 40px;
      height: 24px;
      padding: 0;
      background-color: #373737;
      border-radius: 99px;
    }

    .MuiSwitch-switchBase{
      padding: 3px;
    }

    .MuiSwitch-switchBase.MuiSwitch-colorPrimary.Mui-checked{
      color: #fff;
      transform: translateX(15px);

      + .MuiSwitch-track{
        background-color: #342F1D !important;
        border-color: #E3C050;
        opacity: 1 !important;
      }

      .MuiSwitch-thumb{
        background-image: "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAICAYAAADA+m62AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABySURBVHgBhc3BCcAgDAVQQ8dQoUsIHh2hI7hJ7SYdpb3qoUvoHDYRBClWA0n+4ZEAm5RSytKGEdJa7zlnN4Qtwn1APR1COP+Q994tUsoHADbOOUsp3T1EmSC9N4iNEMJgtl9UYIzxqhh77aECabS4h6he7xlKGxACUUEAAAAASUVORK5CYII=');
        background-size: 10px 8px;
      }
    }

    .MuiSwitch-thumb{
      width: 18px;
      height: 18px;
      background-image: "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACRSURBVHgBvZBNCsQgDIWtcwzxDt34g6u5WZ3bzDW61cUcQoS5RZoUAvaPdtM+EB6PmHyJEI/KGNOfZZJNCKGXUv6stQNn3vuBsvbTi00p5a+17tBGpZRA/waAiO+Tc/5yXbdGwK5UNE+h4pRS3EVqBAd+icTMjIHsI+PVWsdNW1raOQe4dGzxKNu73qwrZ71fE4jDOaPY3r10AAAAAElFTkSuQmCC');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 12px 12px;
    }

    .MuiSwitch-track{
      background-color: #373737 !important;
      border: 1px solid #373737;
      border-radius: 99px;
      height: 22px;
      opacity: 1 !important;
    }
  }
`;