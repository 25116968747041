import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const MonitorIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="none" d="M17.1667 3.11133H3.83341C2.91294 3.11133 2.16675 3.85752 2.16675 4.77799V13.1113C2.16675 14.0318 2.91294 14.778 3.83341 14.778H17.1667C18.0872 14.778 18.8334 14.0318 18.8334 13.1113V4.77799C18.8334 3.85752 18.0872 3.11133 17.1667 3.11133Z" stroke="currentColor" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M7.16675 18.1113H13.8334" stroke="currentColor" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M10.5 14.7783V18.1117" stroke="currentColor" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
};