import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const BookIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="none" d="M5 24.375C5 23.5462 5.32924 22.7513 5.91529 22.1653C6.50134 21.5792 7.2962 21.25 8.125 21.25H25" stroke="currentColor" strokeWidth="2.505" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M8.125 2.5H25V27.5H8.125C7.2962 27.5 6.50134 27.1708 5.91529 26.5847C5.32924 25.9987 5 25.2038 5 24.375V5.625C5 4.7962 5.32924 4.00134 5.91529 3.41529C6.50134 2.82924 7.2962 2.5 8.125 2.5V2.5Z" stroke="currentColor" strokeWidth="2.505" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
};