import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "api/config";

const API_BASE = process.env.REACT_APP_API_URL;

export const api = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl: `${API_BASE}`,
  }),
  reducerPath: "coursesApi",
  tagTypes: [],
  endpoints: (build) => ({
    getCourses: build.query<any, any>({
      query: () => ({ url: `/courses` }),
    }),
    getCourse: build.query<any, any>({
      query: (slug) => ({ url: `/courses/${slug}` }),
    }),
    accountLink: build.query<any, any>({
      query: (data) => ({
        url: `/accountLink/${data.email}`,
      }),
    }),
    checkout: build.mutation<any, any>({
      query: (data) => ({
        url: `/checkout/${data.slug}`,
        method: "post",
        body: {
          email: data.email,
        },
      }),
    }),
    addCourse: build.mutation<any, any>({
      query: (data) => ({
        url: "/courses",
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: {
          img: data.img,
          document: JSON.stringify({ ...data, img: undefined }),
        },
      }),
    }),
    updateCourse: build.mutation<any, any>({
      query: (data) => ({
        url: `/courses/${data._id}`,
        method: "put",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: {
          img: data.img,
          document: JSON.stringify({ ...data, img: undefined }),
        },
      }),
    }),
    sendEmail: build.mutation<any, any>({
      query: (data) => ({
        url: "/sendEmail",
        method: "post",
        body: data,
      }),
    }),
  }),
});

export const {
  useGetCoursesQuery,
  useGetCourseQuery,
  useAddCourseMutation,
  useUpdateCourseMutation,
  useCheckoutMutation,
  useAccountLinkQuery,
  useSendEmailMutation,
} = api;
