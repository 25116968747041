import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Language as LanguageIcon } from "@mui/icons-material";
import { Box, Menu, MenuItem } from "@mui/material";
import FullPageProgress from "./FullPageProgress";
import { useTranslate } from "../../utils/auth";
import { WorldIcon } from "components/Icons/WorldIcon";
const googleLanguage = [
  { name: "Afrikaans", lang: "af" },
  { name: "Albanian", lang: "sq" },
  { name: "Amharic", lang: "am" },
  { name: "Arabic", lang: "ar" },
  { name: "Armenian", lang: "hy" },
  { name: "Assamese", lang: "as" },
  { name: "Aymara", lang: "ay" },
  { name: "Azerbaijani", lang: "az" },
  { name: "Bambara", lang: "bm" },
  { name: "Basque", lang: "eu" },
  { name: "Belarusian", lang: "be" },
  { name: "Bengali", lang: "bn" },
  { name: "Bhojpuri", lang: "bho" },
  { name: "Bosnian", lang: "bs" },
  { name: "Bulgarian", lang: "bg" },
  { name: "Catalan", lang: "ca" },
  { name: "Cebuano", lang: "ceb" },
  { name: "Chinese (Simplified)", lang: "zh-CN" },
  { name: "Chinese (Traditional)", lang: "zh-TW" },
  { name: "Corsican", lang: "co" },
  { name: "Croatian", lang: "hr" },
  { name: "Czech", lang: "cs" },
  { name: "Danish", lang: "da" },
  { name: "Dhivehi", lang: "dv" },
  { name: "Dogri", lang: "doi" },
  { name: "Dutch", lang: "nl" },
  { name: "English", lang: "en" },
  { name: "Esperanto", lang: "eo" },
  { name: "Estonian", lang: "et" },
  { name: "Ewe", lang: "ee" },
  { name: "Filipino (Tagalog)	", lang: "fil" },
  { name: "Finnish", lang: "fi" },
  { name: "French", lang: "fr" },
  { name: "Frisian", lang: "fy" },
  { name: "Galician", lang: "gl" },
  { name: "Georgian", lang: "ka" },
  { name: "German", lang: "de" },
  { name: "Greek", lang: "el" },
  { name: "Guarani", lang: "gn" },
  { name: "Gujarati", lang: "gu" },
  { name: "Haitian Creole", lang: "ht" },
  { name: "Hausa", lang: "ha" },
  { name: "Hawaiian", lang: "haw" },
  { name: "Hebrew", lang: "iw" },
  { name: "Hindi", lang: "hi" },
  { name: "Hmong", lang: "hmn" },
  { name: "Hungarian", lang: "hu" },
  { name: "Icelandic", lang: "is" },
  { name: "Igbo", lang: "ig" },
  { name: "Ilocano", lang: "ilo" },
  { name: "Indonesian", lang: "id" },
  { name: "Irish", lang: "ga" },
  { name: "Italian", lang: "it" },
  { name: "Japanese", lang: "ja" },
  { name: "Javanese", lang: "jv" },
  { name: "Kannada", lang: "kn" },
  { name: "Kazakh", lang: "kk" },
  { name: "Khmer", lang: "km" },
  { name: "Kinyarwanda", lang: "rw" },
  { name: "Konkani", lang: "gom" },
  { name: "Korean", lang: "ko" },
  { name: "Krio", lang: "kri" },
  { name: "Kurdish", lang: "ku" },
  { name: "Kurdish (Sorani)	", lang: "ckb" },
  { name: "Kyrgyz", lang: "ky" },
  { name: "Lao", lang: "lo" },
  { name: "Latin", lang: "la" },
  { name: "Latvian", lang: "lv" },
  { name: "Lingala", lang: "ln" },
  { name: "Lithuanian", lang: "lt" },
  { name: "Luganda", lang: "lg" },
  { name: "Luxembourgish", lang: "lb" },
  { name: "Macedonian", lang: "mk" },
  { name: "Maithili", lang: "mai" },
  { name: "Malagasy", lang: "mg" },
  { name: "Malay", lang: "ms" },
  { name: "Malayalam", lang: "ml" },
  { name: "Maltese", lang: "mt" },
  { name: "Maori", lang: "mi" },
  { name: "Marathi", lang: "mr" },
  { name: "Meiteilon (Manipuri)	", lang: "mni-Mtei" },
  { name: "Mizo	", lang: "lus" },
  { name: "Mongolian", lang: "mn" },
  { name: "Myanmar (Burmese)", lang: "my" },
  { name: "Nepali", lang: "ne" },
  { name: "Norwegian", lang: "no" },
  { name: "Nyanja (Chichewa)	", lang: "ny" },
  { name: "Odia (Oriya)", lang: "or" },
  { name: "Oromo", lang: "om" },
  { name: "Pashto", lang: "ps" },
  { name: "Persian", lang: "fa" },
  { name: "Polish", lang: "pl" },
  { name: "Portuguese (Portugal, Brazil)", lang: "pt" },
  { name: "Punjabi", lang: "pa" },
  { name: "Quechua", lang: "qu" },
  { name: "Romanian", lang: "ro" },
  { name: "Russian", lang: "ru" },
  { name: "Samoan", lang: "sm" },
  { name: "Sanskrit", lang: "sa" },
  { name: "Scots Gaelic", lang: "gd" },
  { name: "Sepedi", lang: "nso" },
  { name: "Serbian", lang: "sr" },
  { name: "Sesotho", lang: "st" },
  { name: "Shona", lang: "sn" },
  { name: "Sindhi", lang: "sd" },
  { name: "Sinhala (Sinhalese)", lang: "si" },
  { name: "Slovak", lang: "sk" },
  { name: "Slovenian", lang: "sl" },
  { name: "Somali", lang: "so" },
  { name: "Spanish", lang: "es" },
  { name: "Sundanese", lang: "su" },
  { name: "Swahili", lang: "sw" },
  { name: "Swedish", lang: "sv" },
  { name: "Tagalog (Filipino)	", lang: "tl" },
  { name: "Tajik", lang: "tg" },
  { name: "Tamil", lang: "ta" },
  { name: "Tatar", lang: "tt" },
  { name: "Telugu", lang: "te" },
  { name: "Thai", lang: "th" },
  { name: "Tigrinya", lang: "ti" },
  { name: "Tsonga", lang: "ts" },
  { name: "Turkish", lang: "tr" },
  { name: "Turkmen", lang: "tk" },
  { name: "Twi (Akan)	", lang: "ak" },
  { name: "Ukrainian", lang: "uk" },
  { name: "Urdu", lang: "ur" },
  { name: "Uyghur", lang: "ug" },
  { name: "Uzbek", lang: "uz" },
  { name: "Vietnamese", lang: "vi" },
  { name: "Welsh", lang: "cy" },
  { name: "Xhosa", lang: "xh" },
  { name: "Yiddish", lang: "yi" },
  { name: "Yoruba", lang: "yo" },
  { name: "Zulu", lang: "zu" },
];

const LanguageTranslate = () => {
  const navigate = useNavigate();
  const [headerOrgEl, setHeaderOrgEl] = useState(null);
  const headerOrgOpen = Boolean(headerOrgEl);
  const scriptRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [translateCookies, setTranslateCookies] = useTranslate();
  const [selectedLang, setSlectedLang] = useState("");
  const headerOrgMenuOpen = (event) => {
    setHeaderOrgEl(event.currentTarget);
  };
  const headerOrgMenuClose = () => {
    setHeaderOrgEl(null);
  };
  useEffect(() => {
    if (translateCookies) {
      setLoading(true);
      if (translateCookies.indexOf("/") > -1) {
        let s = translateCookies.split("/");
        setSlectedLang(s[s.length - 1]);
      }
      setTimeout(() => {
        setLoading(false);
      }, 2500);
    } else {
      setLoading(false);
    }
  }, [translateCookies, navigate]);

  useEffect(() => {
    if (!scriptRef.current) {
      const script = document.createElement("script");
      script.src =
        "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
      script.async = true;
      document.body.appendChild(script);
      scriptRef.current = script;
      window.googleTranslateElementInit = googleTranslateElementInit;
    }
  }, []);
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false,
      },
      "google_translate_element"
    );
  };

  const switchLanguage = (item) => {
    headerOrgMenuClose();
    if (item?.lang) {
      //setLoading(true);
      setTranslateCookies("/en/" + item?.lang);
      let container = document.getElementById("google_translate_element");
      let select = container.getElementsByTagName("select")[0];
      select.value = item?.lang;
      select.dispatchEvent(new Event("change"));
      //setSlectedLang(item?.lang);

      // setTimeout(() => {
      //   setLoading(false);
      // }, 2000);
    }
  };

  return (
    <>
      <Box id="google_translate_element" style={{ display: "none" }} />
      <div className="header-language-box">
        <div
          id="header-language-menu-button"
          className="header-language-box-top"
          aria-controls={headerOrgOpen ? "header-language-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={headerOrgOpen ? "true" : undefined}
          onClick={headerOrgMenuOpen}
        >
          <WorldIcon />
          <div className="header-language-box-top-name" translate="no">
            {selectedLang ? <span>{selectedLang}</span> : <>EN</>}
          </div>
        </div>

        <Menu
          id="header-language-menu"
          anchorEl={headerOrgEl}
          open={headerOrgOpen}
          onClose={headerOrgMenuClose}
          MenuListProps={{
            "aria-labelledby": "header-language-menu-button",
          }}
          className="header-language-box-menu"
        >
          <div className="header-language-box-menu-inner">
            {googleLanguage.map((item, key) => (
              <MenuItem
                key={key}
                className={`header-language-box-menu-inner-item ${
                  selectedLang === item?.lang ? "active" : ""
                } `}
                onClick={() => {
                  switchLanguage(item);
                }}
                title={item?.name}
                translate="no"
              >
                {item?.name}
              </MenuItem>
            ))}
          </div>
        </Menu>
      </div>
      {loading ? (
        <Box
          style={{
            width: "100%",
            height: "100vh",
            position: "fixed",
            left: "0px",
            top: "0px",
            zIndex: 99999,
            background: "#000",
            backdropFilter: "blur(3px)",
          }}
        >
          <FullPageProgress />
        </Box>
      ) : null}
    </>
  );
};

export default LanguageTranslate;
