import { Wrapper, LoaderHeading, Loader } from "./style";

const FullPageProgress = () => (
  <Wrapper>
    <Loader />
    <LoaderHeading>Loading</LoaderHeading>
  </Wrapper>
);

export default FullPageProgress;
