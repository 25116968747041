import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const KeyboardIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_516_10297)">
      <path d="M2.25 3.49902H15.75C16.7856 3.49902 17.625 4.33836 17.625 5.37402V13.624C17.625 14.6596 16.7856 15.499 15.75 15.499H2.25C1.21437 15.499 0.374999 14.6596 0.374999 13.624V5.37402C0.374999 4.33839 1.21437 3.49902 2.25 3.49902ZM15.75 14.749C16.3711 14.749 16.875 14.2451 16.875 13.624V5.37402C16.875 4.75291 16.3711 4.24903 15.75 4.24903H2.25C1.62889 4.24903 1.12501 4.75291 1.12501 5.37402V13.624C1.12501 14.2451 1.62889 14.749 2.25 14.749H15.75Z" stroke="#575757" stroke-width="0.749998"/>
      <path d="M4.73886 7.32747L4.73888 7.32749C4.8864 7.475 4.88645 7.71457 4.73886 7.8622L4.73159 7.86947L4.72474 7.87712C4.66369 7.94528 4.58022 7.9886 4.49052 7.99926C4.44599 7.99667 4.403 7.98659 4.36324 7.96969L4.3539 7.96572L4.34435 7.96226C4.26063 7.93192 4.19447 7.86575 4.16412 7.78203L4.16066 7.77248L4.15669 7.76314C4.13767 7.7184 4.12729 7.66956 4.12651 7.61904L4.12631 7.6062L4.12524 7.59342C4.11685 7.49354 4.14822 7.39551 4.21186 7.32003C4.35989 7.17996 4.59381 7.18242 4.73886 7.32747Z" fill="#575757" stroke="#575757" stroke-width="0.749998"/>
      <path d="M4.31611 11.0066L4.32387 11.0037L4.3315 11.0005C4.47015 10.942 4.63106 10.9725 4.73946 11.0791C4.88634 11.2253 4.88698 11.4623 4.74153 11.6089L4.73807 11.6124L4.73078 11.6197L4.72391 11.6273C4.66286 11.6955 4.57939 11.7388 4.48968 11.7495C4.44515 11.7469 4.40216 11.7368 4.3624 11.7199L4.35434 11.7165L4.34613 11.7134C4.30388 11.6978 4.2645 11.6728 4.23057 11.6396L4.22375 11.6329L4.2166 11.6266C4.06172 11.4897 4.04694 11.2527 4.18467 11.0969L4.18502 11.0965C4.19148 11.0892 4.19426 11.0865 4.19481 11.086L4.1949 11.0861L4.20197 11.0791C4.23406 11.0473 4.27246 11.0228 4.31611 11.0066Z" fill="#575757" stroke="#575757" stroke-width="0.749998"/>
      <path d="M7.46705 7.96823L7.36386 7.96902C7.36311 7.96871 7.36235 7.96839 7.3616 7.96807L7.36058 7.96764C7.31242 7.94734 7.26808 7.91993 7.22958 7.88694C7.19991 7.85373 7.17608 7.81498 7.15926 7.77186L7.15941 7.7718L7.15504 7.76152C7.13603 7.71677 7.12564 7.66794 7.12486 7.61741L7.12466 7.60458L7.12359 7.59179C7.11522 7.49217 7.14642 7.39437 7.20974 7.31897C7.35693 7.18135 7.58823 7.18475 7.7317 7.32933C7.87718 7.47593 7.87652 7.71311 7.72949 7.85935C7.65966 7.92832 7.56516 7.96747 7.46705 7.96823Z" fill="#575757" stroke="#575757" stroke-width="0.749998"/>
      <path d="M11.0031 7.06399C10.7871 6.85098 10.4646 6.78875 10.1856 6.90649C10.0949 6.94474 10.0108 6.998 9.93809 7.06399C9.80084 7.21773 9.73335 7.421 9.75061 7.62649C9.75212 7.72475 9.77237 7.8215 9.81062 7.9115C9.84662 8.00375 9.89988 8.08773 9.96812 8.15899C10.0409 8.22498 10.1249 8.27824 10.2156 8.31649C10.3056 8.35474 10.4024 8.37499 10.5006 8.37651C10.6926 8.35851 10.8719 8.27002 11.0031 8.12901C11.0713 8.05775 11.1246 7.97376 11.1606 7.88151C11.1989 7.79151 11.2191 7.69476 11.2206 7.5965C11.2221 7.39699 11.1433 7.20574 11.0031 7.06399Z" fill="#575757"/>
      <path d="M14.0331 7.0609C13.7309 6.76689 13.2472 6.77364 12.9531 7.07591C12.6591 7.37815 12.6659 7.8619 12.9682 8.15591C13.1099 8.29615 13.3012 8.3749 13.5007 8.37342C13.6004 8.36291 13.6979 8.33218 13.7857 8.28342C13.9739 8.21518 14.1224 8.06668 14.1907 7.87842C14.2289 7.78842 14.2492 7.69167 14.2507 7.59341C14.2521 7.3939 14.1734 7.20265 14.0331 7.0609Z" fill="#575757"/>
      <path d="M14.0028 10.8125C13.7868 10.5995 13.4643 10.5373 13.1853 10.655C13.093 10.691 13.009 10.7443 12.9378 10.8125C12.8095 10.9708 12.748 11.1725 12.7653 11.375C12.7615 11.4253 12.7615 11.4748 12.7653 11.525C12.7735 11.5723 12.7885 11.6173 12.8103 11.66C12.8268 11.708 12.8493 11.753 12.8778 11.795L12.9678 11.9075C13.039 11.9758 13.123 12.029 13.2153 12.065C13.3053 12.1033 13.402 12.1235 13.5003 12.1251L13.4703 12.0951C13.672 12.0958 13.8655 12.0163 14.0073 11.8731C14.299 11.579 14.2968 11.1043 14.0028 10.8125Z" fill="#575757"/>
      <path d="M10.5 10.625H7.50002C7.08599 10.625 6.75 10.961 6.75 11.375C6.75 11.789 7.08599 12.125 7.49999 12.125H10.5C10.914 12.125 11.25 11.789 11.25 11.375C11.25 10.961 10.914 10.625 10.5 10.625Z" fill="#575757"/>
      </g>
      <defs>
      <clipPath id="clip0_516_10297">
      <rect width="18" height="18" fill="white" transform="translate(0 0.5)"/>
      </clipPath>
      </defs>
    </SvgIcon>
  );
};