import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const TickIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6.49987 13.9911C6.16837 13.991 5.8505 13.8593 5.61612 13.6249L-0.267578 7.74113L1.49992 5.97363L6.49987 10.9736L16.4999 0.973633L18.2674 2.74113L7.38362 13.6249C7.14925 13.8593 6.83137 13.991 6.49987 13.9911Z" fill="currentColor"/>
    </SvgIcon>
  );
};