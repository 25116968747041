import { styled, Box, Button, Link, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import Switch from '@mui/material/Switch';

export const ButtonDropdownWrapper = styled('div')`
`;

export const CourseMenu = styled(Menu)`

  > .MuiPaper-elevation{
    background-color: #1f1f1f !important;
    border-color: #1f1f1f !important;
    box-shadow: 0 0 10px rgba(0,0,0,0.4) !important;
    margin-top: 0;
    border-radius: 11px;
    min-width: 1px;
    width: 230px;

    .MuiMenu-list{
      padding-top: 0 !important;
      padding-bottom: 0 !important;

      > li{
        padding: 5px;

        &:hover{
          cursor: default;
          background-color: transparent;
        }
      }
    }

    .MuiMenuItem-root{
      color: #fff;
      text-transform: uppercase;
    }
  }

  .tick-icn{

    .MuiSvgIcon-root{
      width: 12px;
      height: 12px;
      color: #E3C050;
    }
  }
`;

export const ButtonOpener = styled(Button)`
  color: #C0C0C0;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  padding: 3px !important;
  min-height: 1px !important;
  min-width: 1px !important;
  border-radius: 8px;
  text-transform: none;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 6px;

  @media (min-width: 768px){
    gap: 12px;
    font-size: 16px;
    min-height: 38px !important;
  }

  &:hover,
  &[aria-expanded="true"]{
    color: #C0C0C0;
    background-color: #2B2B2B;
    border-color: #2B2B2B;
  }

  .icn-wrap{
    display: block;
    line-height: 0;

    @media (min-width: 768px){
      margin-right: 8px;
    }
  }

  .MuiSvgIcon-root{
    width: 12px;
    height: 12px;

    @media (min-width: 768px){
      width: 18px;
      height: 18px;
    }
  }

  .text-span{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: 20px;

    @media (min-width: 768px){
      max-width: 100px;
    }
    @media (min-width: 992px){
      max-width: 200px;
    }
  }
}
`;

export const DropdownHeader = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  border-bottom: 1px solid #2B2B2B;
  padding: 13px 15px;
  margin: -5px;
`;

export const LogoWrapper = styled(`span`)`
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  overflow: hidden;
  background-color: #000;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1;
  color: #e9e9e9;
  display: none;

  @media (min-width: 576px){
    display: flex;
  }
  @media (min-width: 768px){
    font-size: 14px;
    width: 30px;
    height: 30px;
  }
`;

export const MenuHeading = styled(Typography)`
  flex-grow: 1;
  font-size: 14px;
  line-height: 1;
  font-weight: 700;
  text-transform: none;
`;

export const EditButton = styled(Button)`
  flex-shrink: 0;
  padding: 0;
  border: 0;
  min-width: 1px;
  color: #8C8E95;
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  text-transform: none;
  transition: color 0.3s ease;

  &:hover{
    color: #e9e9e9;
  }
`;

export const Anchor = styled(Box)`
  display: flex;
  align-items: center;
  color: #8C8E95;
  text-decoration: none;
  transition: background-color 0.3s ease;
  border-radius: 8px;
  width: 100%;
  padding: 5px 8px 5px 35px;
  gap: 8px;
  white-space: normal;
  position: relative;

  &:hover{
    background-color: #2b2b2b;
  }
`;

export const IconWrapper = styled(Box)`
  flex-shrink: 0;
  background-color: #131313;
  width: 40px;
  height: 40px;
  border-radius: 11px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiSvgIcon-root{
    width: 15px;
    height: 15px;

    @media (min-width: 768px){
      width: 22px;
      height: 22px;
    }
  }
`;

export const DescriptionWrapper = styled(Box)`
  flex-grow: 1;
  padding-right: 50px;
  position: relative;
  color: #E9E9E9;
`;

export const ItemHeading = styled(Typography)`
  color: #E9E9E9;
  font-size: 14px;
  line-height: 1.35;
  font-weight: 700;
  display: block;
  margin-bottom: 4px;
  text-transform: none;
`;

export const TextPara = styled(Typography)`
  font-size: 14px;
  line-height: 1.35;
  text-transform: none;
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;

export const DropdownFooter = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 13px;
  border-top: 1px solid #2B2B2B;
  margin: -5px -2px;
`;

export const FootLink = styled(Link)`
  text-decoration: none;
  color: #C0C0C0;
  font-size: 13px;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 12px;
  text-transform: none;
  transition: color 0.3s ease;
  white-space: normal;

  &:hover{
    color: #e9e9e9;
  }

  .MuiSvgIcon-root{
    width: 16px;
    height: 16px;
  }
`;

export const CaptionText = styled(Typography)`
  color: #E3C050;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.35;
  display: block;
  text-transform: none;
`;

export const ButtonDrag = styled(Button)`
  min-width: 1px;
  color: #575757;
  background-color: transparent;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;

  &:hover{
    color: #e9e9e9;
    background-color: transparent;
  }

  .MuiSvgIcon-root{
    width: 18px;
    height: 18px;
  }
`;

export const SwitchEnabler = styled(Switch)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  width: 40px;
  height: 24px;

  .MuiSwitch-switchBase.MuiSwitch-colorPrimary.Mui-checked{
    color: #fff;
    transform: translateX(17px);

    + .MuiSwitch-track{
      background-color: #342F1D !important;
      border-color: #E3C050;
      opacity: 1 !important;
    }

    .MuiSwitch-thumb{
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAICAYAAADA+m62AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABySURBVHgBhc3BCcAgDAVQQ8dQoUsIHh2hI7hJ7SYdpb3qoUvoHDYRBClWA0n+4ZEAm5RSytKGEdJa7zlnN4Qtwn1APR1COP+Q994tUsoHADbOOUsp3T1EmSC9N4iNEMJgtl9UYIzxqhh77aECabS4h6he7xlKGxACUUEAAAAASUVORK5CYII=');
      background-size: 10px 8px;
    }
  }

  .MuiSwitch-thumb{
    width: 18px;
    height: 18px;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACRSURBVHgBvZBNCsQgDIWtcwzxDt34g6u5WZ3bzDW61cUcQoS5RZoUAvaPdtM+EB6PmHyJEI/KGNOfZZJNCKGXUv6stQNn3vuBsvbTi00p5a+17tBGpZRA/waAiO+Tc/5yXbdGwK5UNE+h4pRS3EVqBAd+icTMjIHsI+PVWsdNW1raOQe4dGzxKNu73qwrZ71fE4jDOaPY3r10AAAAAElFTkSuQmCC');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 12px 12px;
  },

  .MuiSwitch-track{
    background-color: #373737 !important;
    border: 1px solid #373737;
    border-radius: 99px;
    height: 22px;
    opacity: 1 !important;
  }

  .MuiSwitch-switchBase{
    padding: 2px;
  }
`;

export const AnchorLink = styled(Link)`
  display: flex;
  align-items: center;
  color: #8C8E95;
  text-decoration: none;
  transition: background-color 0.3s ease;
  border-radius: 8px;
  width: 100%;
  padding: 8px;
  gap: 8px;
  white-space: normal;

  &:hover{
    background-color: #2b2b2b;
  }

  .DescriptionWrapper{
    padding-right: 0;
  }
`;