import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const UserIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="none" d="M15 15.75V14.25C15 13.4543 14.6839 12.6913 14.1213 12.1287C13.5587 11.5661 12.7957 11.25 12 11.25H6.00009C5.20445 11.25 4.4414 11.5661 3.87879 12.1287C3.31619 12.6913 3.00012 13.4543 3.00012 14.25V15.75" stroke="currentColor" strokeWidth="1.24999" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M8.99997 8.24994C10.6568 8.24994 11.9999 6.90681 11.9999 5.24997C11.9999 3.59313 10.6568 2.25 8.99997 2.25C7.34313 2.25 6 3.59313 6 5.24997C6 6.90681 7.34313 8.24994 8.99997 8.24994Z" stroke="currentColor" strokeWidth="1.24999" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
};