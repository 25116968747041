import { ColWrapper, CreateMeetingWrapper, LogoWrapper, TextParagraph, ActionsWrapper, FormGroup, FormField, ButtonPrimary, ImageWrapper, AlignWrapper, ContainerWrapper, ColsWrapper, InnerAlign, LabelIcon, ButtonSecondary } from "./style";
import SixAIlogo from "../../assets/6ixAIlogo.svg"
import Image1 from "../../assets/img0001.png"
import { KeyboardIcon } from "components/Icons/KeyboardIcon";
import { VideoIcon } from "components/Icons/VideoIcon";

export const Landing = () => {
  return (
    <CreateMeetingWrapper className="CreateMeetingWrapper">
      <AlignWrapper>
        <ContainerWrapper>
          <ColsWrapper>
            <ColWrapper>
              <InnerAlign>
                <LogoWrapper>
                  <img src={SixAIlogo} width={"131"} height={"54"} alt="6ix Connect" />
                </LogoWrapper>
                <TextParagraph variant="body1">AI-powered video meetings connecting investors & comapnies. Host, summarize, search, and share video meetings.</TextParagraph>
                <ActionsWrapper>
                  <FormGroup>
                    <LabelIcon>
                      <KeyboardIcon />
                    </LabelIcon>
                    <FormField name="meeting_name" placeholder="Enter Meeting Name" hiddenLabel />
                  </FormGroup>
                  <ButtonPrimary 
                    onClick={() => {
                      window.location.href = "https://events.6ix.com/preview/company-wide-morning-meeting";
                    }}>
                    <VideoIcon />
                    New meeting
                  </ButtonPrimary>
                </ActionsWrapper>
                {/* <ActionsWrapper>
                  <ButtonPrimary>
                    <VideoIcon />
                    New meeting
                  </ButtonPrimary>
                  <FormGroup className="small">
                    <LabelIcon>
                      <KeyboardIcon />
                    </LabelIcon>
                    <FormField name="meeting_name" value="abc-2345-bgh" hiddenLabel />
                  </FormGroup>
                  <ButtonSecondary>
                    JOIN
                  </ButtonSecondary>
                </ActionsWrapper> */}
              </InnerAlign>
            </ColWrapper>
            <ColWrapper>
              <ImageWrapper>
                <img src={Image1} alt="" />
              </ImageWrapper>
            </ColWrapper>
          </ColsWrapper>
        </ContainerWrapper>
      </AlignWrapper>
    </CreateMeetingWrapper>
  );
};
