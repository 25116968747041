import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const UpDownIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="12"
      height="17"
      viewBox="0 0 12 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="none" d="M9.3312 6.17858L5.99995 2.50146L2.6687 6.17858M2.6687 10.8195L5.99995 14.4966L9.3312 10.8195" stroke="currentColor" strokeWidth="0.999759"/>
    </SvgIcon>
  );
};