import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const CrossIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4.84839 5.30737L16.1627 16.6216" stroke="currentColor" strokeWidth="2.17151" strokeLinecap="round"/>
      <path d="M4.84839 16.6204L16.1627 5.30609" stroke="currentColor" strokeWidth="2.17151" strokeLinecap="round"/>
    </SvgIcon>
  );
};