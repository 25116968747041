import { Box } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { Landing } from "pages/Landing";

export const UnauthorizedRoutes = () => (
  <Box mt={"50px"}>
    <Routes>
      <Route path="/" element={<Landing />} />
    </Routes>
  </Box>
);
